import parse from 'url-parse';
import Cookies from 'js-cookie';
import {
  UTM_SOURCE_COOKIE_KEY,
  UTM_TERM_COOKIE_KEY,
  UTM_CAMPAIGN_COOKIE_KEY,
} from '../constants';

export interface UtmDataFromCookies {
  utmSource: string | undefined;
  utmTerm: string | undefined;
  utmCampaign: string | undefined;
}

export function getUtmData(): UtmDataFromCookies {
  const utmSource = Cookies.get(UTM_SOURCE_COOKIE_KEY);
  const utmTerm = Cookies.get(UTM_TERM_COOKIE_KEY);
  const utmCampaign = Cookies.get(UTM_CAMPAIGN_COOKIE_KEY);

  return {
    utmSource,
    utmTerm,
    utmCampaign,
  };
}

export function saveUtmData(url: string): void {
  const { query } = parse(url, true);

  if (query.utm_source) {
    Cookies.set(UTM_SOURCE_COOKIE_KEY, query.utm_source, {
      expires: 90,
    });
  }

  if (query.utm_term) {
    Cookies.set(UTM_TERM_COOKIE_KEY, query.utm_term, {
      expires: 90,
    });
  }

  if (query.utm_campaign) {
    Cookies.set(UTM_CAMPAIGN_COOKIE_KEY, query.utm_campaign, {
      expires: 90,
    });
  }
}
