/**
 * Fetch specific key value from the session storage.
 *
 * @param key
 * @param value
 */
export function getFromStorage(key: string): string | null {
  if (typeof window === 'undefined') {
    return null;
  }

  return window.sessionStorage.getItem(key);
}

/**
 * Save a specific key value to the session storage.
 *
 * @param key
 * @param value
 */
export function saveToStorage(key: string, value: string): void {
  if (typeof window === 'undefined') {
    return;
  }

  if (!value) {
    return;
  }

  window.sessionStorage.setItem(key, value);
}

/**
 * Remove a specific key value to the session storage.
 *
 * @param key
 * @param value
 */
export function removeFromStorage(key: string): void {
  if (typeof window === 'undefined') {
    return;
  }

  window.sessionStorage.removeItem(key);
}
