import { useEffect } from 'react';
import { useRouter } from 'next/router';
import 'core-js/features/global-this';
import './globals.css';
import Head from 'next/head';
import dynamic from 'next/dynamic';
import App, { AppContext, AppProps } from 'next/app';
import { SpeedInsights } from '@vercel/speed-insights/next';
import { ThemeProvider } from '@material-ui/styles';
import { ThemeProvider as CoreThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import defaultTheme from '../common/theme';
import IntlProvider from '../components/Unknown/IntlProvider';
import { saveConversionPath } from '../common/utils/conversionPath';
import { saveUtmData } from '../common/utils/saveUtmData';
import { saveReferrer } from '../common/utils/saveReferrer';
import { config } from '@fortawesome/fontawesome-svg-core';
import '@fortawesome/fontawesome-svg-core/styles.css';
config.autoAddCss = false;
import {
  enqDetectAbandon,
  enqDetectAbandonClose,
} from '../common/utils/enquiry';
import { PrismicPreview } from '@prismicio/next';
import { repositoryName } from '@/prismicio';

const CookieBannerContainer = dynamic(
  () => import('../components/Unknown/CookieBannerContainer'),
  {
    ssr: false,
  },
);

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function MyApp({ Component, pageProps }: AppProps) {
  const router = useRouter();

  useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles) {
      jssStyles?.parentElement?.removeChild(jssStyles);
    }
  }, []);

  useEffect(() => {
    // Paths should be saved as full URLs which can be confusing because path is not supposed to be full URL
    // Be very careful when changing the format here because we have trackers that is using the full URL.

    const storage = globalThis?.sessionStorage;
    if (!storage) return;
    const prevPath = storage.getItem('currentPath') || '';
    const currentPath = globalThis.location.href;
    storage.setItem('prevPath', prevPath);
    storage.setItem('currentPath', currentPath);

    if (prevPath !== globalThis.location.href) {
      saveConversionPath(currentPath);
    }

    enqDetectAbandon(prevPath, currentPath);
  }, [router.asPath]);

  useEffect(() => {
    saveUtmData(globalThis.location.href);
    saveReferrer();
  }, []);

  useEffect(() => {
    const win = window || globalThis;

    win.addEventListener('beforeunload', () => {
      enqDetectAbandonClose();
    });
  });

  const app = (
    <>
      <IntlProvider>
        <ThemeProvider theme={defaultTheme}>
          <CoreThemeProvider theme={defaultTheme}>
            <CssBaseline />
            <Head>
              <meta
                name="viewport"
                content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no"
              />
            </Head>
            <Component {...pageProps} />
            <CookieBannerContainer />
          </CoreThemeProvider>
        </ThemeProvider>
      </IntlProvider>
      <SpeedInsights />
      <PrismicPreview repositoryName={repositoryName} />
    </>
  );
  if (typeof window !== 'undefined') {
    const cache = createCache({ key: 'custom', prepend: true });
    return <CacheProvider value={cache}>{app}</CacheProvider>;
  }
  return app;
}

MyApp.getInitialProps = async (appContext: AppContext) => {
  // calls page's `getInitialProps` and fills `appProps.pageProps`
  const appProps = await App.getInitialProps(appContext);

  return {
    ...appProps,
    pageProps: { ...appProps.pageProps },
  };
};

export default MyApp;
