import axios from 'axios';
import { FormikValues } from 'formik';
import { ENQUIRY_FORM_API_URL, ENQUIRY_ENGAGEMENT_API_URL } from '../constants';

export const eventEnquiryEngagement = 'enquiry_engagement';

const sendEnquiryFormData = async (values: FormikValues) => {
  const {
    email,
    firstName,
    lastName,
    address,
    yourNeeds,
    recaptchaResponse,
    skillsSeekedOther,
    developersNeededOther,
    hasFullTimeCTOOther,
    stateofRequirementsOther,
    howDidYouHear,
    attachmentS3Objects,
    sessionId,
    company,
    conversionPath,
    referrer,
    utmSource,
    utmTerm,
    utmCampaign,
  } = values;

  const response = await axios.post(ENQUIRY_FORM_API_URL, {
    email,
    company,
    referrer,
    firstName,
    lastName,
    address: {
      country: address,
    },
    recaptchaResponse,
    type: 'enquiry',
    utmSource,
    utmTerm,
    utmCampaign,
    yourNeeds,
    skillsSeeked: values.skillsSeeked.map((skill: string) => Number(skill)),
    skillsSeekedOther,
    developersNeeded: Number(values.developersNeeded),
    developersNeededOther,
    hasFullTimeCTO: Number(values.hasFullTimeCTO),
    hasFullTimeCTOOther,
    preferredStartDate: Number(values.preferredStartDate),
    annualBudget: Number(values.annualBudget),
    stateofRequirements: Number(values.stateofRequirements),
    stateofRequirementsOther,
    howDidYouHear,
    attachmentUrls: values.attachmentUrls,
    attachmentS3Objects,
    sessionId,
    conversionPath,
    version: 2,
    newsLettersOptIn: Number(values.newsLettersOptIn),
  });

  return response;
};

export const sendEngagementData = async (values: FormikValues) => {
  const {
    sessionId,
    enquiryStep,
    yourNeeds,
    skillsSeeked,
    skillsSeekedOther,
    developersNeeded,
    developersNeededOther,
    jobDescriptionUrl,
    hasFullTimeCTO,
    hasFullTimeCTOOther,
    preferredStartDate,
    annualBudget,
    stateofRequirements,
    stateofRequirementsOther,
    howDidYouHear,
  } = values;

  const response = await axios.post(ENQUIRY_ENGAGEMENT_API_URL, {
    date: new Date().toLocaleDateString(),
    eventName: eventEnquiryEngagement,
    sessionId,
    enquiryStep,
    yourNeeds,
    skillsSeeked,
    skillsSeekedOther,
    developersNeeded,
    developersNeededOther,
    jobDescriptionUrl,
    hasFullTimeCTO,
    hasFullTimeCTOOther,
    preferredStartDate,
    annualBudget,
    stateofRequirements,
    stateofRequirementsOther,
    howDidYouHear,
  });

  return response;
};

export default sendEnquiryFormData;
