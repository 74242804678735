const CLUTCH_REVIEWS_LINK = 'https://clutch.co/profile/arcanys';
const CALENDLY_WIDGET_URL =
  'https://assets.calendly.com/assets/external/widget.js';
const CALENDLY_LINK =
  'https://calendly.com/fredjoye/60-minutes-meeting-discovery';

const VENTURES_URL = 'https://ventures.arcanys.com';

// eslint-disable-next-line import/prefer-default-export
const GLASSDOOR_REVIEWS_LINK =
  'https://www.glassdoor.com/Reviews/Arcanys-Reviews-E1764287.htm';
const GLASSDOOR_BADGE_IMAGE_SRC =
  'https://www.glassdoor.com/api/widget/verticalStarRating.htm?e=1764287';
const INSTAGRAM_POSTS_ID_API = `https://graph.instagram.com/me/media?fields=id&access_token=${process.env.INSTAGRAM_ACCESS_TOKEN}`;
const INSTAGRAM_GRAPH_API = `https://graph.instagram.com/`;
const INSTAGRAM_POSTS_QUANTITY = 6;
const RECAPTCHA_SITE_KEY = process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY;
const RECAPTCHA_SECRET_KEY = process.env.NEXT_PUBLIC_RECAPTCHA_SECRET_KEY;

const APPBAR_MOBILE_HEIGHT = 65;
const APPBAR_DESKTOP_HEIGHT = 85;
const HERO_BANNER_HEIGHT = 520;
const MOBILE_HERO_BANNER_HEIGHT = 440;
const TABS_HEIGHT_MOBILE = 48;
const CHARACTER_LIMIT = 20000;
const CHARACTER_MINIMUM = 100;
const MAX_ROWS = 4;

const GOOGLE_MAPS_HEAD_OFFICE_PIN =
  'https://maps.google.com/maps?width=400&height=300&hl=en&q=Arcanys,%20Salinas%20Drive,%20Cebu+(Arcanys)&t=&z=17&ie=UTF8&iwloc=B&output=embed';

const GOOGLE_MAPS_HEAD_OFFICE_GET_DIRECTION =
  'https://www.google.com/maps/dir/?api=1&destination=3,+Arcanys,+12th+Floor,+JY+IT+Centre,+1+Salinas+Dr,+Cebu+City,+6000+Cebu,+Philippines';

const GOOGLE_MAPS_AU_OFFICE_PIN =
  'https://maps.google.com/maps?width=400&height=300&hl=en&q=maps/place/Suite+2,+Level+2%2F695+Burke+Rd,+Camberwell+VIC+3124,+Australia&t=&z=17&ie=UTF8&iwloc=B&output=embed';

const GOOGLE_MAPS_AU_OFFICE_GET_DIRECTION =
  'https://www.google.com/maps/dir/?api=1&destination=Australian+Electoral+Commission,+Level+2%2F695+Burke+Rd,+Camberwell+VIC+3124,+Australia';

const RECRUITERBOX_API_URL = 'https://jsapi.recruiterbox.com/v1/openings';
const RECRUITERBOX_API_FILTERED_TAGS = 'hide';
const ALLOWED_CHARACTERS_REG_EXP =
  // eslint-disable-next-line no-control-regex
  /[^\u0000-\u007F\u0080-\u00FF\u0100-\u017F\u0180-\u024F\u0250-\u02AF\u02B0-\u02FF\u2000-\u206F\u2070-\u209F\u20A0-\u20CF]+/gi;

const ABOUT_PAGE_IDS = {
  story: 'story',
  team: 'team',
  purpose: 'purpose',
  initiatives: 'initiatives',
  contactUs: 'contact-us',
};

const CAREERS_PAGE_IDS = {
  jobOpenings: 'job-openings',
  lifeAtArcanys: 'life-at-arcanys',
  careerTalk: 'career-talk',
};

const LEGAL_PAGE_IDS = [
  {
    id: 'privacy-policy',
    documentName: 'privacy-policy-en.md',
  },
  {
    id: 'terms-and-conditions',
    documentName: 'terms-and-conditions-en.md',
  },
];
const COOKIE_ACCEPTED_KEY = 'accepted_cookies';
const COMPANY_NAME = 'arcanys';

const CAREER_DETAILS_VIDEO_SRC = `https://www.youtube.com/embed/Jm9b98GinRc`;

const MAILCHIMP_SUBSCRIBE_URL =
  'https://arcanys.us12.list-manage.com/subscribe/post-json?u=30245f60821775c5ed5721903&id=f12e29f2a7&c=mailchimpCallback&_=1636461632993&EMAIL=';

const ACCEPTED_FILE_FORMATS = ['.doc', '.docx', '.xls', '.xlsx', '.pdf'];

const ENQUIRY_ACCEPTED_FILE_FORMATS = [
  '.png',
  '.jpg',
  '.doc',
  '.docx',
  '.xls',
  '.xlsx',
  '.pdf',
];

const RECRUITERBOX_ACCEPTED_FILE_FORMATS = ['.doc', '.docx', '.txt', '.pdf'];
const RECRUITERBOX_API_LIMIT = 100;

const MAX_ATTACHED_FILES = 5;

const BYTES_IN_MB = 1_048_576;
const BYTES_IN_KB = 1_024;
const MAX_FILE_SIZE = 20 * BYTES_IN_MB;

// eslint-disable-next-line prefer-destructuring
const VERCEL_URL = process.env.VERCEL_URL;

const apiUrl =
  process.env.NEXT_PUBLIC_FORM_API_URL ||
  'https://staging-forms-api.arcanys.com';

const ENQUIRY_ENGAGEMENT_API_URL = `${apiUrl}/enquiry/engagement`;
const ENQUIRY_FORM_API_URL = `${apiUrl}/submissions`;
const CONTACT_FORM_API_URL = `${apiUrl}/submissions`;
const GENERATE_PRESIGNED_URL_API_URL = `${apiUrl}/generate-presigned-url`;

const COOKIE_KEY = 'accepted_cookies';
const CONVERSION_PATH_COOKIE_KEY = 'conversion_path';
const UTM_SOURCE_COOKIE_KEY = 'utm_source';
const UTM_TERM_COOKIE_KEY = 'utm_term';
const UTM_CAMPAIGN_COOKIE_KEY = 'utm_campaign';
const REFERRER_COOKIE_KEY = 'referrer';

const PAGE_META = {
  title: {
    homepage:
      'Arcanys | Custom Software Development & Team Augmentation Company',
    service: 'Services | Trusted Dedicated Developers, Ready to Join Your Team',
    enquiry: 'Enquiry | Hire the Best Software Developers in the Philippines',
    aboutStory:
      'Our Story | Swiss Founding Duo Alan Debonneville & Frederic Joye',
    aboutTeam: 'Our Team | A winning Swiss-Filipino Blend of Talent and Grit',
    aboutPurpose: 'Our Purpose | Power Tech Innovators with Trusted Dev Teams',
    aboutInitiatives:
      'About Arcanys Ventures & Arcanys Early Learning Foundation',
    aboutContact: 'Contact Us | Connect with Our Co-Founders Frederic and Alan',
    careerJobOpenings: "We're Hiring! See the Best Remote Tech Job Offers",
    careerLifeAtArcanys:
      'Life at Arcanys | A Happy Bunch of Thinkers & Problem Solvers',
    careerCareerTalk: 'Career opportunities | Career Talk',
    insights: 'Arcanys Blog | Fresh Insights on Tech Teams, IT BPO and More',
    reviewSass: 'Review: SaaS | Software Dev Staff Augmentation for ERP Firm',
    reviewMobile: 'Review: Mobile | Dev & Maintenance for Training Company',
    terms: 'Arcanys | Terms and Conditions',
    privacy: 'Arcanys | Privacy Policy',
  },
  description: {
    homepage:
      "Philippines' Top Software Development & Team Augmentation company. Hire the tech talents you need from a pool of 200+ dedicated software engineers.",
    service:
      'Access the development skills and can-do attitude you need with 200+ on-demand software engineers.',
    enquiry:
      "Tell our co-founders what skills you're looking for. We fill your need for robust software capabilities with flexible arrangements that ensure continuity.",
    aboutStory:
      '11 years in the making, Arcanys is recognized by Clutch as the most highly recommended software development company in the Philippines.',
    aboutTeam:
      'Accelerate your development with the most amazing dedicated teams of IT talents in the Philippines. A tight-knit, passionate bunch of tech experts.',
    aboutPurpose:
      "We're not all things to all people. We specialize in building highly skilled teams of software developers, and we do it well. ",
    aboutInitiatives:
      'Arcanys equity program backs bold entrepreneurs through tech resources investment, while our foundation serves the Philippines underprivileged.',
    aboutContact:
      "Need a better tech hire option? Tell us where you're struggling and one of our founders will contact you shortly.",
    careerJobOpenings:
      "Job openings for Software developers, QA testers, AWS engineers and many more.  Small teams, new technologies and career growth - that's Arcanys.",
    careerLifeAtArcanys:
      'Join the gang and get to work with innovative software teams across the globe, grow in a startup-like environment and enjoy awesome perks.',
    careerCareerTalk:
      "Read the best tips for your next job interview, discover Arcanys' referral program and meet the team here!",
    insights:
      'Gain in-depth insights into tech and outsourcing worlds, pick up some tips for your next software projects and discover our experience in the industry',
    reviewSass:
      "We unlocked Koobani's full potential and helped the SaaS company reach spectacular growth, becoming a market leader in the B2B Sales space. See how.",
    reviewMobile:
      'See how we helped this mobile app-based company improve the work of a previous agency and propel them to greater heights.',
    terms:
      'Hey there. Please make sure you understand and accept the Terms and Conditions before using the Arcanys.com website.',
    privacy:
      'We process information in accordance with our privacy policy. Please read carefully.',
  },
};

export {
  CHARACTER_LIMIT,
  CHARACTER_MINIMUM,
  MAX_ROWS,
  GLASSDOOR_REVIEWS_LINK,
  GLASSDOOR_BADGE_IMAGE_SRC,
  INSTAGRAM_POSTS_ID_API,
  INSTAGRAM_GRAPH_API,
  INSTAGRAM_POSTS_QUANTITY,
  GOOGLE_MAPS_HEAD_OFFICE_PIN,
  GOOGLE_MAPS_AU_OFFICE_PIN,
  GOOGLE_MAPS_HEAD_OFFICE_GET_DIRECTION,
  GOOGLE_MAPS_AU_OFFICE_GET_DIRECTION,
  APPBAR_MOBILE_HEIGHT,
  APPBAR_DESKTOP_HEIGHT,
  RECAPTCHA_SITE_KEY,
  RECAPTCHA_SECRET_KEY,
  ABOUT_PAGE_IDS,
  CAREERS_PAGE_IDS,
  LEGAL_PAGE_IDS,
  RECRUITERBOX_API_URL,
  RECRUITERBOX_API_FILTERED_TAGS,
  COMPANY_NAME,
  CAREER_DETAILS_VIDEO_SRC,
  BYTES_IN_KB,
  BYTES_IN_MB,
  MAX_FILE_SIZE,
  MAX_ATTACHED_FILES,
  COOKIE_ACCEPTED_KEY,
  ENQUIRY_ENGAGEMENT_API_URL,
  ENQUIRY_FORM_API_URL,
  ENQUIRY_ACCEPTED_FILE_FORMATS,
  COOKIE_KEY,
  RECRUITERBOX_ACCEPTED_FILE_FORMATS,
  HERO_BANNER_HEIGHT,
  TABS_HEIGHT_MOBILE,
  MAILCHIMP_SUBSCRIBE_URL,
  MOBILE_HERO_BANNER_HEIGHT,
  CLUTCH_REVIEWS_LINK,
  CONTACT_FORM_API_URL,
  ACCEPTED_FILE_FORMATS,
  PAGE_META,
  ALLOWED_CHARACTERS_REG_EXP,
  GENERATE_PRESIGNED_URL_API_URL,
  CONVERSION_PATH_COOKIE_KEY,
  RECRUITERBOX_API_LIMIT,
  UTM_SOURCE_COOKIE_KEY,
  UTM_TERM_COOKIE_KEY,
  UTM_CAMPAIGN_COOKIE_KEY,
  REFERRER_COOKIE_KEY,
  VERCEL_URL,
  CALENDLY_LINK,
  CALENDLY_WIDGET_URL,
  VENTURES_URL,
};
