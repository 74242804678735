import {
  createTheme,
  Theme,
  ThemeOptions,
  alpha,
} from '@material-ui/core/styles';

import {
  CommonColors,
  Palette,
  TypeBackground,
} from '@material-ui/core/styles/createPalette';

interface IBackground extends TypeBackground {
  primaryMain: string;
  primaryDark: string;
  secondaryMain: string;
  secondaryDark: string;
  ceruleanBlue: string;
}

interface ICommonColors extends CommonColors {
  blue: string;
  yellow: string;
}

interface IPalette extends Palette {
  background: IBackground;
  common: ICommonColors;
}
export interface ITheme extends Theme {
  colors: any;
  palette: IPalette;
}
interface IThemeOptions extends ThemeOptions {
  palette: IPalette;
}

const defaultTheme = createTheme({
  spacing: 5,
  breakpoints: {
    values: {
      xs: 0,
      sm: 480,
      md: 769,
      lg: 1024,
      xl: 1452,
    },
  },
});

const colors = {
  primaryMain: '#7761ff',
  primaryDark: '#523CDA',
  secondaryMain: '#E5CAA2',
  secondaryDark: '#E5E5E5',
  textPrimary: '#393939',
  textDark: '#262626',
  textSecondary: '#6F7172',
  bgPrimaryMain: '#FFFFFF',
  bgPrimaryDark: '#F8F8F8',
  bgPrimaryLight: '#F0F0F0',
  bgSecondaryMain: '#122636',
  errorMain: '#FF3D2E',
  black: '#000000',
  dividerColor: '#CCCCCC',
  light: '#EAEAEA',
  lightGray: '#E5E5E5',
  mediumGray: '#848484',
  darkGray: '#777777',
  white: '#FFFFFF',
  bgSecondaryDark: '#17232d',
  blue: '#0066FF',
  lighterGrey: '#ECECE7',
  ceruleanBlue: '#187FB9',
  textLightGrey: '#AAAAAA',
  yellow: '#F5A624',
  lightGreen: '#00CE9c',
};

const opacity = {
  none: 1,
  s: 0.05,
  m: 0.12,
  l: 0.15,
  xl: 0.3,
  xxl: 0.4,
};

const borderWidth = {
  m: '3px',
  l: '5px',
};

const shadows = {
  s: `10px 10px 40px ${alpha(colors.black, opacity.s)}`,
  m: `0px 2px 32px ${alpha(colors.black, opacity.m)}`,
  l: `4px 4px 12px 4px ${alpha(colors.black, opacity.l)}`,
  xl: `0 1px 13px ${alpha(colors.black, opacity.xxl)}`,
};

const theme = createTheme({
  spacing: 5,
  breakpoints: {
    values: {
      xs: 0,
      sm: 480,
      md: 769,
      lg: 1024,
      xl: 1452,
    },
  },
  components: {
    MuiFormControl: {
      styleOverrides: {
        root: {
          width: '100%',
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          backgroundColor: 'transparent',
          marginTop: defaultTheme.spacing(0),
          marginBottom: defaultTheme.spacing(11),
          '&.Mui-expanded': {
            marginBottom: defaultTheme.spacing(11),
          },
          '&:before': {
            display: 'none',
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          width: 'fit-content',
          minHeight: 0,
          paddingLeft: defaultTheme.spacing(0),
          paddingRight: defaultTheme.spacing(0),
          '&.Mui-expanded': {
            minHeight: 0,
          },
        },
        expandIconWrapper: {
          width: 14,
          color: colors.textPrimary,
          marginLeft: defaultTheme.spacing(3),
          alignSelf: 'flex-end',
          paddingBottom: defaultTheme.spacing(1),
          paddingTop: defaultTheme.spacing(1),
          [defaultTheme.breakpoints.up('md')]: {
            marginLeft: defaultTheme.spacing(5),
          },
          '&.Mui-expanded': {
            width: 18,
            color: colors.primaryMain,
          },
        },
        content: {
          marginTop: defaultTheme.spacing(0),
          marginBottom: defaultTheme.spacing(0),
          fontSize: 24,
          lineHeight: '28px',
          color: colors.textPrimary,
          '&.Mui-expanded': {
            marginTop: defaultTheme.spacing(0),
            marginBottom: defaultTheme.spacing(0),
            color: colors.primaryMain,
          },
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          paddingRight: defaultTheme.spacing(0),
          paddingBottom: defaultTheme.spacing(0),
          paddingLeft: defaultTheme.spacing(0),
          fontSize: 16,
          lineHeight: '19px',
          color: colors.textSecondary,
          [defaultTheme.breakpoints.up('md')]: {
            paddingBottom: defaultTheme.spacing(5),
          },
        },
      },
    },
    MuiTimeline: {
      styleOverrides: {
        root: {
          position: 'relative',
          marginBottom: defaultTheme.spacing(0),
          paddingTop: defaultTheme.spacing(0),
          paddingBottom: defaultTheme.spacing(0),
          paddingLeft: defaultTheme.spacing(0),
          paddingRight: defaultTheme.spacing(0),
        },
      },
    },
    MuiTimelineItem: {
      styleOverrides: {
        root: {
          paddingTop: defaultTheme.spacing(0),
          paddingBottom: defaultTheme.spacing(12),
          paddingLeft: defaultTheme.spacing(0),
          paddingRight: defaultTheme.spacing(0),
          '&:before': {
            display: 'none',
          },
          [defaultTheme.breakpoints.up('md')]: {
            paddingBottom: defaultTheme.spacing(20),
          },
        },
      },
    },
    MuiTimelineContent: {
      styleOverrides: {
        root: {
          paddingTop: defaultTheme.spacing(0),
          paddingLeft: defaultTheme.spacing(5),
          paddingBottom: defaultTheme.spacing(0),
          [defaultTheme.breakpoints.up('md')]: {
            paddingLeft: defaultTheme.spacing(9),
          },
        },
      },
    },
    MuiTimelineDot: {
      styleOverrides: {
        outlined: {
          marginTop: defaultTheme.spacing(0),
          marginBottom: defaultTheme.spacing(0),
          paddingTop: defaultTheme.spacing(0.6),
          paddingBottom: defaultTheme.spacing(0.6),
          paddingLeft: defaultTheme.spacing(0.6),
          paddingRight: defaultTheme.spacing(0.6),
          width: 6,
          height: 6,
          borderWidth: borderWidth.l,
          backgroundColor: colors.white,
        },
      },
    },
    MuiTimelineSeparator: {
      styleOverrides: {
        root: {
          marginBottom: defaultTheme.spacing(-12),
          [defaultTheme.breakpoints.up('md')]: {
            marginBottom: defaultTheme.spacing(-20),
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          color: colors.textPrimary,
          fontWeight: 400,
          [defaultTheme.breakpoints.up('md')]: {
            fontSize: 18,
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          overflow: 'hidden',
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            backgroundColor: colors.bgPrimaryDark,
            color: alpha(colors.textPrimary, opacity.xl),
          },
        },
        colorPrimary: {
          color: colors.bgPrimaryMain,
          backgroundColor: colors.primaryMain,
          '&:hover': {
            backgroundColor: colors.primaryDark,
          },
        },
        colorSecondary: {
          borderWidth: 1,
          borderStyle: 'solid',
          borderColor: colors.lightGray,
          color: colors.textDark,
          '&:hover': {
            color: colors.primaryMain,
            borderColor: colors.primaryMain,
          },
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          paddingLeft: defaultTheme.spacing(5),
          paddingRight: defaultTheme.spacing(5),
          [defaultTheme.breakpoints.up('sm')]: {
            paddingLeft: defaultTheme.spacing(5),
            paddingRight: defaultTheme.spacing(5),
          },
        },
        maxWidthLg: {
          [defaultTheme.breakpoints.up('lg')]: {
            maxWidth: 1290,
          },
        },
        disableGutters: {
          paddingLeft: defaultTheme.spacing(0),
          paddingRight: defaultTheme.spacing(0),
          [defaultTheme.breakpoints.up('sm')]: {
            paddingLeft: defaultTheme.spacing(0),
            paddingRight: defaultTheme.spacing(0),
          },
          [defaultTheme.breakpoints.up('lg')]: {
            paddingLeft: defaultTheme.spacing(0),
            paddingRight: defaultTheme.spacing(0),
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          minHeight: 80,
          marginBottom: defaultTheme.spacing(-4),
          paddingTop: defaultTheme.spacing(1),
          paddingBottom: defaultTheme.spacing(1),
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          paddingRight: defaultTheme.spacing(1),
          paddingLeft: defaultTheme.spacing(1),
          fontSize: 14,
          color: colors.darkGray,
          '&.Mui-focused': {
            color: colors.mediumGray,
          },
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          '&:before': {
            borderBottomColor: colors.lightGray,
          },
          '&:after': {
            borderBottomWidth: 1,
          },
        },
        input: {
          paddingRight: defaultTheme.spacing(1),
          paddingLeft: defaultTheme.spacing(1),
          color: colors.primaryDark,
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          paddingTop: defaultTheme.spacing(2),
          paddingBottom: defaultTheme.spacing(1),
          paddingLeft: defaultTheme.spacing(3),
          color: colors.textPrimary,
          '&:focus': {
            backgroundColor: 'transparent',
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          height: 40,
          fontSize: 16,
          fontWeight: 700,
          borderRadius: 54,
          paddingLeft: defaultTheme.spacing(5),
          paddingRight: defaultTheme.spacing(5),
        },
        sizeSmall: {
          paddingLeft: defaultTheme.spacing(3),
          paddingRight: defaultTheme.spacing(3),
          height: 28,
          fontSize: 14,
        },
        colorPrimary: {
          backgroundColor: colors.primaryMain,
          color: colors.white,
          '&.Mui-disabled': {
            backgroundColor: colors.bgPrimaryLight,
            color: alpha(colors.textDark, 0.5),
            opacity: opacity.none,
          },
        },
        colorSecondary: {
          backgroundColor: colors.bgPrimaryLight,
          color: alpha(colors.textDark, 0.5),
          '&:hover': {
            backgroundColor: colors.lightGray,
          },
          '&.Mui-disabled': {
            backgroundColor: colors.bgPrimaryLight,
            color: alpha(colors.textDark, 0.3),
            opacity: opacity.none,
          },
        },
        label: {
          paddingLeft: defaultTheme.spacing(0),
          paddingRight: defaultTheme.spacing(0),
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          paddingTop: defaultTheme.spacing(0),
          paddingRight: defaultTheme.spacing(0),
          paddingBottom: defaultTheme.spacing(0),
          paddingLeft: defaultTheme.spacing(0),
        },
        dense: {
          paddingBottom: defaultTheme.spacing(1),
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: 23,
          justifyContent: 'center',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          maxHeight: 60,
          fontSize: 18,
          lineHeight: '20px',
          paddingTop: defaultTheme.spacing(4),
          paddingBottom: defaultTheme.spacing(4),
          paddingLeft: defaultTheme.spacing(5),
          paddingRight: defaultTheme.spacing(5),
          textTransform: 'none',
          fontWeight: 700,
          [defaultTheme.breakpoints.up('md')]: {
            fontSize: 20,
          },
          '&:hover': {
            '& .MuiButton-endIcon': {
              '&:has(svg[data-icon="arrow-right-2"])': {
                translate: '50%',
              },
            },
          },
        },

        outlined: {
          paddingTop: defaultTheme.spacing(3.5),
          paddingBottom: defaultTheme.spacing(3.5),
          paddingLeft: defaultTheme.spacing(4.5),
          paddingRight: defaultTheme.spacing(4.5),
          borderWidth: borderWidth.m,
          borderRadius: '5px',
          fontSize: 16,
          '&.Mui-disabled': {
            borderWidth: borderWidth.m,
            borderColor: colors.secondaryDark,
            color: colors.secondaryDark,
          },
        },
        contained: {
          boxShadow: 'none',
          '&.Mui-disabled': {
            backgroundColor: colors.secondaryDark,
            color: alpha(colors.textDark, 0.5),
          },
          '&:hover': {
            boxShadow: 'none',
          },
        },
        containedPrimary: {
          '&:hover': {
            backgroundColor: colors.primaryDark,
          },
        },
        containedSecondary: {
          backgroundColor: colors.bgPrimaryMain,
          color: colors.primaryMain,
          '&:hover': {
            backgroundColor: colors.primaryMain,
            color: colors.white,
          },
        },
        outlinedPrimary: {
          borderColor: colors.primaryMain,
          '&:hover': {
            backgroundColor: colors.primaryMain,
            color: colors.bgPrimaryMain,
            borderWidth: borderWidth.m,
            borderColor: colors.primaryMain,
          },
        },
        outlinedSecondary: {
          borderColor: colors.bgPrimaryMain,
          color: colors.bgPrimaryMain,
          '&:hover': {
            backgroundColor: colors.bgPrimaryMain,
            color: colors.primaryMain,
            borderWidth: borderWidth.m,
            borderColor: colors.bgPrimaryMain,
          },
        },
        endIcon: {
          display: 'flex',
          width: 'auto',
          height: 'auto',
          justifyContent: 'center',
          alignItems: 'center',
          marginLeft: defaultTheme.spacing(2),
          transition: 'translate .2s ease-in-out',
          '&>*:nth-of-type(1)': {
            fontSize: 14,
          },
        },
        sizeLarge: {
          paddingTop: defaultTheme.spacing(3.2),
          paddingBottom: defaultTheme.spacing(3.2),
          paddingLeft: defaultTheme.spacing(8),
          paddingRight: defaultTheme.spacing(8),
        },
        outlinedSizeLarge: {
          paddingTop: defaultTheme.spacing(2),
          paddingBottom: defaultTheme.spacing(2),
          paddingLeft: defaultTheme.spacing(9.5),
          paddingRight: defaultTheme.spacing(9.5),
        },
        textSizeSmall: {
          [defaultTheme.breakpoints.up('md')]: {
            fontSize: 18,
          },
        },
        sizeSmall: {
          paddingTop: defaultTheme.spacing(2.5),
          paddingBottom: defaultTheme.spacing(2.5),
          maxHeight: 50,
        },
        outlinedSizeSmall: {
          maxHeight: 50,
          paddingTop: defaultTheme.spacing(2.5),
          paddingBottom: defaultTheme.spacing(2.5),
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          width: '100%',
          [defaultTheme.breakpoints.up('md')]: {
            width: 'auto',
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          paddingRight: defaultTheme.spacing(2.5),
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          alignItems: 'start',
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          '&.anchor-link': {
            marginTop: defaultTheme.spacing(-24),
            paddingTop: defaultTheme.spacing(24),
            [defaultTheme.breakpoints.up('md')]: {
              marginTop: defaultTheme.spacing(-40),
              paddingTop: defaultTheme.spacing(40),
            },
          },
        },
        gutterBottom: {
          marginBottom: defaultTheme.spacing(4),
        },
      },
    },
  },
  palette: {
    primary: {
      main: colors.primaryMain,
      dark: colors.primaryDark,
    },
    secondary: {
      main: colors.secondaryMain,
    },
    background: {
      primaryMain: colors.bgPrimaryMain,
      primaryDark: colors.bgPrimaryDark,
      secondaryMain: colors.bgSecondaryMain,
      secondaryDark: colors.bgSecondaryDark,
      ceruleanBlue: colors.ceruleanBlue,
    },
    text: {
      primary: colors.textPrimary,
      secondary: colors.textSecondary,
      info: colors.textLightGrey,
    },
    error: {
      main: colors.errorMain,
    },
    grey: {
      100: colors.lighterGrey,
      200: colors.lightGray,
      400: colors.dividerColor,
      600: colors.mediumGray,
      700: colors.darkGray,
      900: colors.textDark,
    },
    divider: colors.light,
    common: {
      white: colors.white,
      blue: colors.blue,
      yellow: colors.yellow,
    },
  },
  shape: {
    borderRadius: 10,
  },
  typography: {
    fontFamily: 'Europa, sans-serif',
    h1: {
      fontSize: 32,
      fontWeight: 700,
      lineHeight: '40px',
      color: colors.textPrimary,
      [defaultTheme.breakpoints.up('md')]: {
        fontSize: 64,
        lineHeight: '80px',
      },
    },
    h2: {
      fontSize: 28,
      fontWeight: 700,
      lineHeight: '35px',
      color: colors.textPrimary,
      [defaultTheme.breakpoints.up('md')]: {
        fontSize: 48,
        lineHeight: '60px',
      },
    },
    h3: {
      fontSize: 24,
      fontWeight: 700,
      lineHeight: '30px',
      color: colors.textPrimary,
      [defaultTheme.breakpoints.up('md')]: {
        fontSize: 30,
        lineHeight: '36px',
      },
    },
    h4: {
      fontSize: 20,
      fontWeight: 700,
      lineHeight: '25px',
      color: colors.textPrimary,
      [defaultTheme.breakpoints.up('md')]: {
        fontSize: 24,
        fontWeight: 400,
        lineHeight: '30px',
      },
    },
    code: {
      fontFamily:
        '"Menlo", "DejaVu Sans Mono", "Liberation Mono", "Consolas", "Ubuntu Mono", "Courier New", "andale mono", "lucida console", monospace',
      fontSize: 14,
      color: colors.textDark,
      whiteSpace: 'pre',
    },
    quote: {
      fontSize: 18,
      lineHeight: '24px',
      fontWeight: 700,
      color: alpha(colors.textDark, 0.5),
    },
    body1: {
      fontSize: 16,
      fontWeight: 400,
      lineHeight: '20px',
      color: colors.textSecondary,
    },
    body2: {
      fontSize: 14,
      fontWeight: 700,
      lineHeight: '17px',
      color: colors.textSecondary,
      [defaultTheme.breakpoints.up('md')]: {
        fontSize: 16,
        lineHeight: '20px',
      },
    },
    caption: {
      fontSize: 14,
      lineHeight: '17px',
      color: colors.textSecondary,
      fontStyle: 'italic',
    },
  },
  shadows: ['none', shadows.s, shadows.m, shadows.l, shadows.xl],
} as unknown as IThemeOptions);

export default theme;
