// Utility functions for capturing user input in the abandonment tracker.
import { Subject, debounceTime } from 'rxjs';
import {
  countries,
  CountryOption,
} from '../../components/Unknown/Country/countryData';
import {
  getFromStorage,
  removeFromStorage,
  saveToStorage,
} from './sessionStorage';
import { eventEnquiryEngagement, sendEngagementData } from '../api/enquiryForm';
import { getConversionPaths } from './conversionPath';
import { getReferrer } from './saveReferrer';
import { getUtmData } from './saveUtmData';

export const eventEnquiryView = 'enquiry_view';
export const eventEnquiryAbandon = 'enquiry_abandon';
export const sessionIdKey = 'enquirySessionId';
export const countryKey = 'country';
export const enquiryStepKey = 'enquiryStep';
export const firstNameKey = 'firstName';
export const lastNameKey = 'lastName';
export const emailKey = 'email';
export const companyKey = 'company';
export const yourNeedsKey = 'yourNeeds';
export const skillsSeekedKey = 'skillsSeeked';
export const skillsSeekedOtherKey = 'skillsSeekedOther';
export const developersNeededKey = 'developersNeeded';
export const developersNeededOtherKey = 'developersNeededOther';
export const jobDescriptionUrlKey = 'jobDescriptionUrl';
export const hasFullTimeCTOKey = 'hasFullTimeCTO';
export const hasFullTimeCTOOtherKey = 'hasFullTimeCTOOther';
export const preferredStartDateKey = 'preferredStartDate';
export const annualBudgetKey = 'annualBudget';
export const stateofRequirementsKey = 'stateofRequirements';
export const stateofRequirementsOtherKey = 'stateofRequirementsOther';
export const howDidYouHearKey = 'howDidYouHear';

export const enquiryStorageKeys = [
  sessionIdKey,
  countryKey,
  enquiryStepKey,
  firstNameKey,
  lastNameKey,
  emailKey,
  companyKey,
  yourNeedsKey,
  skillsSeekedKey,
  skillsSeekedOtherKey,
  developersNeededKey,
  developersNeededOtherKey,
  jobDescriptionUrlKey,
  hasFullTimeCTOKey,
  hasFullTimeCTOOtherKey,
  preferredStartDateKey,
  annualBudgetKey,
  stateofRequirementsKey,
  stateofRequirementsOtherKey,
  howDidYouHearKey,
];

export enum EnquiryStep {
  Step1 = 'Step 1',
  Step2 = 'Step 2',
  Step3 = 'Step 3',
  ThankYou = 'Thank You',
}

// Wrap the sending of events via rxjs debounce to avoid multiple and unnecessary calls.

const viewSubject = new Subject();
const engagementSubject = new Subject();

/**
 * Emit the sendview subject.
 */
export function enqExecSendView(): void {
  viewSubject.next(null);
}

/**
 * Send enquiry_view event
 */
export function enqSendView(): void {
  const sessionId = getFromStorage(sessionIdKey);

  if (!sessionId) {
    return;
  }

  gtag('event', eventEnquiryView);
}

/**
 * Sends engagement data from storage
 */
export async function sendEngmtData(): Promise<void> {
  const engagementData: { [key: string]: string | null } = {};

  enquiryStorageKeys.forEach((key) => {
    if (
      key === firstNameKey ||
      key === lastNameKey ||
      key === emailKey ||
      key === companyKey
    ) {
      return;
    }

    engagementData[key] = getFromStorage(key);
  });

  const conversionPath = getConversionPaths();
  const referrer = getReferrer();
  const { utmSource, utmTerm, utmCampaign } = getUtmData();

  await sendEngagementData({
    ...engagementData,
    conversionPath,
    referrer,
    utmSource,
    utmTerm,
    utmCampaign,
  });
}

/**
 * Send enquiry_engagement event
 */
export async function enqSendEngagement(): Promise<void> {
  if (typeof window === 'undefined') {
    return;
  }

  const sessionId = getFromStorage(sessionIdKey);

  if (!sessionId) {
    return;
  }

  gtag('event', eventEnquiryEngagement);

  await sendEngmtData();
}

/**
 * Save a specific key value to the session storage.
 * Checks if the user accepted the cookies.
 *
 * @param key
 * @param value
 */
export function enqSaveToStorage(key: string, value: string): void {
  if (!value) {
    return;
  }

  saveToStorage(key, value);
  engagementSubject.next(null);
}

/**
 * Set a sessionId for the enquiry session if it doesn't exist yet.
 * TODO: account for when user accepted the cookies at the middle of enquiry form.
 */
export function enqSetSessionId(): void {
  const timestamp = new Date().getTime();
  enqSaveToStorage(sessionIdKey, String(timestamp));
}

/**
 * Clear the existing sessionId when user revisits the enquiry page.
 */
export function enqClearSessionData(): void {
  if (typeof window === 'undefined') {
    return;
  }

  enquiryStorageKeys.forEach((key) => {
    removeFromStorage(key);
  });
}

/**
 * Save the selected country by the user.
 */
export function enqSaveCountry(country: string): void {
  enqSaveToStorage(countryKey, country);
}

/**
 * Return the Country object to set the selected value on the country options.
 * @returns
 */
export function enqGetCountryObject(): CountryOption | null | undefined {
  const country = getFromStorage(countryKey);

  if (!country) {
    return null;
  }

  return countries.find((value) => value.label === country);
}

/**
 * Set the current step.
 * @param step
 */
export function enqSetStep(step: EnquiryStep): void {
  const currentStep = getFromStorage(enquiryStepKey);

  if (!currentStep) {
    enqSaveToStorage(enquiryStepKey, step);
  }

  // If user is in Step 3 and goes back to previous steps then ignore
  if (
    currentStep === EnquiryStep.Step3 &&
    [EnquiryStep.Step1, EnquiryStep.Step2].includes(step)
  ) {
    return;
  }

  // If user is in Step 2 and goes back to Step 1
  if (currentStep === EnquiryStep.Step2 && EnquiryStep.Step1 === step) {
    return;
  }

  enqSaveToStorage(enquiryStepKey, step);
}

/**
 * Send enquiry_view event
 */
export function enqSendAbandon(): void {
  const sessionId = getFromStorage(sessionIdKey);

  if (!sessionId) {
    return;
  }

  gtag('event', eventEnquiryAbandon);
}

/**
 * Detect and fire abandonment if the user leaves the page.
 * @param prevUrl
 * @param currentUrl
 */
export function enqDetectAbandon(prevUrl: string, currentUrl: string): void {
  if (!(prevUrl && currentUrl)) {
    return;
  }

  const prevPath = new URL(prevUrl).pathname;
  const currPath = new URL(currentUrl).pathname;
  // User is either in enquiry page or thank you page.
  if (currPath === '/enquiry' || currPath === '/enquiry/thankyou') {
    return;
  }

  // The previous page is not enquiry
  if (prevPath !== '/enquiry') {
    return;
  }

  enqSendAbandon();
}

/**
 * Detect if the user close the browser and the current page is enquiry.
 * @returns
 */
export function enqDetectAbandonClose(): void {
  if (!(window && window.location && window.location.pathname === '/enquiry')) {
    return;
  }

  enqSendAbandon();
}

viewSubject
  .asObservable()
  .pipe(debounceTime(1000))
  .subscribe(() => {
    enqSendView();
  });

engagementSubject
  .asObservable()
  .pipe(debounceTime(1000))
  .subscribe(() => {
    enqSendEngagement();
  });
