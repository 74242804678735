import parse from 'url-parse';
import Cookies from 'js-cookie';
import { REFERRER_COOKIE_KEY } from '../constants';

export function getReferrer(): string | undefined {
  return Cookies.get(REFERRER_COOKIE_KEY);
}

export function saveReferrer(): void {
  const { referrer } = document;

  if (!referrer) {
    return;
  }

  const { host } = parse(document.referrer);

  if (host.indexOf('arcanys.com') > -1) {
    return;
  }

  if (host.indexOf('vercel.app') > -1) {
    return;
  }

  Cookies.set(REFERRER_COOKIE_KEY, referrer, { expires: 90 });
}
