import Cookies from 'js-cookie';
import { CONVERSION_PATH_COOKIE_KEY } from '../constants';

export interface ConversionPath {
  path: string;
  timestamp: string;
}

export function clearConversionPaths(): void {
  Cookies.remove(CONVERSION_PATH_COOKIE_KEY);
}

export function getConversionPaths(): ConversionPath[] {
  const paths = Cookies.get(CONVERSION_PATH_COOKIE_KEY);
  if (paths) {
    return JSON.parse(paths);
  }

  return [];
}

export function saveConversionPath(path: string): void {
  let paths = getConversionPaths();

  if (!Array.isArray(paths)) {
    paths = [];
  }

  const updated = paths.concat([
    {
      path,
      timestamp: new Date().toString(),
    },
  ]);

  Cookies.remove(CONVERSION_PATH_COOKIE_KEY);
  Cookies.set(CONVERSION_PATH_COOKIE_KEY, JSON.stringify(updated));
}
